
import React from 'react'
import { Link } from 'gatsby'

import Meta from '../helpers/Meta'

export default function NotFoundPage() {
	return <div className='Page centered'>

		<Meta title="Page not found" />

		<main>

			<h1 style={{ margin: '10vh 0 3vh 0', fontSize: 'xxx-large'}}>404</h1>

			<p style={{ fontSize: 'x-large', margin: '2em 0'}}>Page not found</p>

		</main>

	</div>
}
